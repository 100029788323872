// ============================================================================================================
// Footer
// -----------------------------------------------------------------------------------------------------------
@import 'global/footer';

footer {

    p {
        font-size: em-calc(12px);
        margin-bottom: em-calc(10px);
    }

    .footer-branding {
        display: none; // hide logo for VANS-EU. TODO: remove in VFDP-75330
    }

    .footer-locator {
        .button {
            @include breakpoint(medium) {
                max-width: 205px;
            }
        }
    }

	.footer-util {
        @include breakpoint(medium) {
            #copyright {
                @include grid-column-f4($columns: 9, $float: left);
            }

            #local {
                @include grid-column-f4($columns: 3, $push: 9, $float: right);
            }
        }
	}

	input[type="text"]	{
		background-color: $backgroundColorDark;
		border: 1px solid get-color(eclipse-2);
		color: $fontInvert;
		box-shadow: inset 1px 1px 5px $black;
	}

    .footer-localize {
        .selected-region {
            font-family: $body-font-family;
            letter-spacing: inherit;

            .icon-arrow-down-indicator-open {
                margin: 0 10px 0 4px; // Align current flag with dropdown flags by pulling down arrow out
            }
        }

        .dropdown-pane {
            border-bottom: 0;
        }
    }

}
